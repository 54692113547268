export const AllowedCollectionList = [
    '0x749f5ddf5ab4c1f26f74560a78300563c34b417d',
    '0xf661d58cfe893993b11d53d11148c4650590c692',
    '0x1ff052df592ffcf42fe6a4173975d351dcc99d45',
    '0x927a51275a610cd93e23b176670c88157bc48af2',
    '0xd4190dd1da460fc7bc41a792e688604778820ac9',
    '0xcd041f40d497038e2da65988b7d7e2c0d9244619',
    '0x38544d6cc9f98968007cf91aaee4a8abe2fb37d2',
    "0xba0c9cf4da821dba98407cc4f9c11f6c7a5f9bbc",
    "0x05844e9ae606f9867ae2047c93cac370d54ab2e1",
    "0x78d61c684a992b0289bbfe58aaa2659f667907f8",
    "0xff1f1a1125184c5ad03a508ee210dc5ef3741b09",
    "0x5bfeb3bedda0acd031be68ad2aa73f574b4c0994",
    "0xfe290336a7fb1d5fdccde1c4ffeb714ab6892bc2",
    "0xa72d82e3003faadb893086b5e379194191ab472d",
    "0xe8bfe9e1ca49e7dc0c6d01bca8ae5ef5a30c8d3b",
    "0x364350d93fc86580ec23ab427c0e899083ecd599",
    "0x99b59ed222f9d228fb96ba54541731e8c6f1df64",
    "0x970b632540e075043d6ac8e8c649d4d19fbe42db",
    "0xf4052755cccf326a6791e02ceff90e243f356f23",
    "0x05844e9ae606f9867ae2047c93cac370d54ab2e1",
    "0xccdf1373040d9ca4b5be1392d1945c1dae4a862c",
    "0x212590b0dac9502e0591b79db4cedeb6356dcc36",
    "0xf661d58cfe893993b11d53d11148c4650590c692",
    "0x223e16c52436cab2ca9fe37087c79986a288fffa",
    "0x1ff052df592ffcf42fe6a4173975d351dcc99d45",
    "0x67f4732266c7300cca593c814d46bee72e40659f",
    "0xd71b53fe1df51075c5a965956cdc87421c2ffed7",
    "0x1b1b94975ff6b87013f20e2ff8fe0460e6b18b2b",
    "0xd3ed75b60b5edbf4110e778d3053fedb3e2f2a30",
    "0x0bb2053ea7621fa6324641aee6ce856a0b9f8595",
    "0xc7c9567bd3020e8148e43d0831c33d4843f0b0e1",
    "0x28472a58a490c5e09a238847f66a68a47cc76f0f",
    "0x455c732fee7b5c3b09531439b598ead4817d5274",
    "0x749f5ddf5ab4c1f26f74560a78300563c34b417d",
    "0x7ab2352b1d2e185560494d5e577f9d3c238b78c5",
    "0xd6f4a923e2ecd9ab7391840ac78d04bfe40bd5e1",
    "0x8b8d1225bb21ca07812ff3c2ee9358f7b5d90eca",
    "0xeb6c5accafd8515c1b9e4c794bdc41532c5543ec",
    "0x4bd77619a75c8eda181e3587339e7011da75bf0e",
    "0x87660380efc270677f62d8d1b45cc1d0b6c60f47",
    "0x9009ef580549252dfd13d423e5dc9b07895084a1",
    "0x364350d93fc86580ec23ab427c0e899083ecd599",
    "0xf24897f2e1668d9d2ac84d260bb5155672166407",
    "0x51a3db97d612e7ac3d8e69ed6dfea08d5ef20d3a",
    "0x8b6d881cb985c2c49e893a522735852839c58737",
    "0xf51bbb5726513b7005d25f2973309661e5206556",
    "0x038d94dfe3ebdd02af5e605b1d9e21cfcfb9d41a",
    "0x0e220a4f3957c17a2e780922dbc13cb2c9aa4274",
    "0x0477a628bd5722f56646b094126d4489b121b5ea",
    "0xbfb99893d04f72e2916c8f266e1b2962871a8dd4",
    "0x4c04517e467f25f8c95634872c505a59a60200f4",
    "0x3693345b9265b15786873ceb05af69dbadb97b93",
    "0x92a462984ac7961a0e88b196651fc19c0045e1fe",
    "0xff1f1a1125184c5ad03a508ee210dc5ef3741b09",
    "0x364350d93fc86580ec23ab427c0e899083ecd599",
    "0xd9c942b906b7c8a38eb2a9ef2d8d30cb6925db34",
    "0xabf66ca534f8a5081303e3873f0f4771c67b7b45",
    "0xf4052755cccf326a6791e02ceff90e243f356f23",
    "0x2a459947f0ac25ec28c197f09c2d88058a83f3bb",
    "0x76acf2775374b6b8731aba327447020e9d8ab869",
    "0x4ce6c369516560b1e6fa49d41096746293c0a87f",
    "0x2bd016017e1f6a7d0948334017e9037028dede98",
    "0x725d24995d500caefd8aad41b9b36bba3280f29b",
    "0xc3f3ef3929392fdc697c5800d6cd18af73377a8f",
    "0x15609d4bf09986b0cabbacab4477b965a630eadc",
    "0x653434f3bcdd1f3e09ea07552d8228bc7876713e",
    "0xe8f88d16f24255fcfab25959705d724406d67d9d",
    "0xa51df3ff6202675e8e9c95d4978a815f6ea11a0b",
    "0x2218e323ab826775e9fd026098a200606d4dd32c",
    "0xcbe4e48e6c883b313c06a002009289501178339d",
    "0xba01c17942f63fcb6f67513e5b0646b4ddf7c8c7",
    "0x71dc46607f31f30510f0ccc670b0963f431b212a",
    "0x91028695e1c266f02dff4c481669b1a48ba96d64",
    "0x7c035e6de5e6b80307747bf9c23e74cc65a3b763",
    "0xc807ce5f1fd6515cbbe9def35e086e60e720b60b",
    "0xe83a546513cc1dc8f9d3d3ddab898b54e6153cfe",
    "0x7f8cba17511ac40270bb4ef13eba47165e577cc8",
    "0x932f05045a01e679ab95a29ba383d9c0954502bf",
    "0xbd8437ca41b43bdae92d1fb68bd8b36225e78f62",
    "0x155bab02984bcaf25c60ca643688b0e4339c3bc8",
    "0xe7484f96d20ee75e146c1917d094ac64f59699cd",
    "0xf6775ad730637420c6d63fa948b564189d3a456d",
    "0xf4d215a4e75beda4bc99609b2af2702e7d2d1111",
    "0x698be66243d4431af18c377b1db82fc09af85db6",
    "0xffe99a2604ae1b606490675475b4db1aa0b9917b",
    "0x1a5dec5283a686a7efead3f25e407e7da7fb4b5f",
    "0x517cedc05f38211113e58663ed4eabed854c0f77",
    "0xf897e2cd445bdc5ac02a8d116caa2c2fb767fdf1",
    "0xeea14f2a8cfaf73736397832ab890bb28ce263bc",
    "0x96c0eb1d1c7e0fdf7eceba3474f1e681a3bf854c",
    "0x352a956fec11a743f739cd5c30a54090deec45f2",
    "0xd854add818cc7c1225624e5c6b61ba81f656ba62",
    "0x6b6ba11ffbcbdc0f22517b972278fa40650ac00f",
    "0x7044a182eb235a86713e05f25ee44531f838a9eb",
    "0x6f7164b378fdbeed629806c9bbcbbbae318dc87c",
    "0x495a0e16eac093f2fbf99b3f8da1aa5fafa4a4fe",
    "0xc3127dc36f1333f3a34ffcc21c0920ba9a6dd837",
    "0xa123d85f522a55a556ccba1e15cffc67c7afdcd8",
    "0xdc61b17587975317fb621b725c6d5be51ac9a55b",
    "0x4b5504e8619cd65692c6bbbedc54e6d7afe5bff5",
    "0x6a1c801ec142596046b0327d50b1fa6dcfd368bf",
    "0xa32e8ef4b15820cee80fa0573a957f11caec6007",
    "0x9f5dcd096e85accecf7f2b2ec0a132cf44fe7996",
    "0xfde389b3e688f12c8efd5548b93540c3f06bfbb0",
    "0x9172d60b2475060f3671e07028340c4cbaa4e263",
    "0x9c11774f5813cab12318ee6d23fb7768f95ae906",
    "0xf16ab2d54e95cf9d8922a244c1c09107c3b96888",
    "0xef90f515ceb7c02f112b277553fa0ee774bca7ea",
    "0xbf5450a58e660740cb9eed444bc3658f2a1b7bb3",
    "0xe2559f4b2f78e139ca3128b5f32d0b7d4d2bd234",
    "0x248e6d478578216822635b95c7a1970a16223836",
    "0x27931513a87b62889992a76c3d975b6c712d909d",
    "0x750314b875e8cff5baa385db3e172686ca1fce40",
    "0xa5c875a893b082edcbdf70a23b01cb10e4768865",
    "0x9d8542ef1237933badbe867291f6c0434b8d315c",
    "0x16c309b6b01402ec196e4ae9a05946f5a0f4d6c4",
    "0x3e249ae5a200230a1f25b3dee35755463156b382",
    "0xf3f1798ee4d40b78d32a2fe321d045a591e7103a",
    "0x80be35056d9467841a74dcd80253729c508cad32",
    "0xcbe6cbd8c3e73d0a3e2cd4f24e4c0dbb61299643",
    "0x7f44bce8c31386500b30e02f371e797ad3410349",
    "0x5fd0102e00ec829ef34ac4f0056a1698dfe210d9",
    "0x0f4ea45f6b9828ba6ac7400504a66d93f4c93c25",
    "0xfdba313e9d2e52299ec317a6fa05a141767e61b4",
    "0xffd17d9dd02c6bb3096528f36d0c2cb9335fac9d",
    "0x96e55659845ca96deba2b8748d8987a7d2d94f4a",
    "0xefded257ca71b9e3bbc9c0959a1ef4ad494c8897",
    "0x7ee1c07be8bf6c16c1cb3075aea488309f48b12e",
    "0xdb65b48446dc4fc383d7e91ffa64c812a1b36a4e",
    "0x02a898554a3122a07764ef1b4cedb123cbb1d075",
    "0xe2a98ba4f46fa47d938be8dfc82c82c35700c5f0",
    "0x5b9c3ef22c4f8c5308552d6e595c04ed4cbc7aa2",
]
