import { FC } from 'react'
import { Anchor, Flex, Text } from '../primitives'

type SectionTitleProps = {
  title: string
}

const SectionTitle: FC<SectionTitleProps> = ({ title }) => (
  <Text style="subtitle1" css={{ color: '$gray12', mb: 8 }}>
    {title}
  </Text>
)

type SectionLinkProps = {
  name: string
  href: string
}

const SectionLink: FC<SectionLinkProps> = ({ name, href }) => (
  <Anchor
    target="_blank"
    rel="noopener noreferrer"
    href={href}
    weight="medium"
    css={{ fontSize: 14, mt: 16, mr: 20 }}
  >
    {name}
  </Anchor>
)

const developerSectionLinks = [
  {
    name: 'Docs',
    href: 'https://docs.reservoir.tools/docs',
  },
  {
    name: 'API Reference',
    href: 'https://docs.reservoir.tools/reference/overview',
  },
  {
    name: 'Github',
    href: 'https://github.com/reservoirprotocol',
  },
  {
    name: 'Testnets',
    href: 'https://testnets.reservoir.tools',
  },
]

const companySectionLinks = [
  {
    name: 'Terms of Use',
    href: 'https://www.notion.so/beyondclub/Birka-Terms-of-Use-eac8e2c759c14730bba910d712dbd617?pvs=4',
  },
  {
    name: 'Privacy Policy',
    href: 'https://www.notion.so/beyondclub/Privacy-Policy-97fc185845da4d298845bf0fcdbf6ced?pvs=4',
  },
  {
    name: 'Notation based on the Specified Commercial Transaction Act',
    href: 'https://www.notion.so/beyondclub/Notation-based-on-the-Specified-Commercial-Transaction-Act-87b6e78182934c6abbd3171c97c34b2a?pvs=4',
  },
]

export const Footer = () => {
  return (
    <Flex
      justify="between"
      css={{
        borderTop: '1px solid $gray7',
        borderStyle: 'solid',
        pt: '$5',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 36,
        '@bp600': {
          flexDirection: 'row',
          gap: 0,
        },
      }}
    >
      <Flex
        // direction="row"
        css={{
          alignItems: 'flex-start',
          justifyContent: 'inherit',
          flexDirection: 'column',
          width: '100%',
          '@bp600': {
            flexDirection: 'row',
            gap: 0,
          },
        }}
      >
        <section>
          {companySectionLinks.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </section>
        <SectionTitle title="Powered by birka" />
      </Flex>
    </Flex>
  )
}
